

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { connect } from "react-redux";
import { PostPackagePricings } from "redux/Actions/wizard";
import Loading from "components/Loading/loading";
import _ from "lodash";
import { setActSelectPackingPricing, setActSelectPackingPricingData } from "redux/Actions/wizard";
import Breadcrumbs from "examples/Breadcrumbs";
import Spin from "components/Spin";
import TokenIcon from "@mui/icons-material/Token";
import axios from "axios";
import { RiDivideFill } from "react-icons/ri";

function specialService(data) {
  let total = 0;
  _.forEach(data, (service) => {
    total += service.Price;
  });
  return total;
}

function PostageType(props) {
  const { values } = props.formData;
  const { getBtnState, packageType, packageData, packagePricings, SelectedPackagePricing } = props;
  const [selectedsWizard, setWizardSelection] = useState("");
  const [loadPostage, setLoadPostage] = useState(false);
  const [packageTypes, setPackageTypes] = useState([]);
  const [selectedPostageType, setSelectedPostageType] = useState("");

  const getPostages = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.id_token}`,
      ShipperHash: localStorage.ShipperHash,
      "x-api-key": "6f40874a-9568-457f-8543-901fd614741e",
    };
    setLoadPostage(true);
    let url = `${process.env.REACT_APP_API_BASE_URL}/postage/cost-estimate?PrintOption=${values.printType == "OS" ? "PrintOneSide" : "PrintTwoSide"
      }&Color=${values.colorType == "C" ? "True" : "False"}&PageCount=${props.PageCount}`;
    axios
      .get(url, { headers: headers })
      .then((res) => {
        setPackageTypes(res.data);
      })
      .catch((err) => {
        console.log("error > ", err);
      })
      .finally(() => {
        setLoadPostage(false);
      });
  };
  useEffect(() => {
    getPostages();
  }, []);

  useEffect(() => { }, [SelectedPackagePricing]);

  const handleWizardSelect = (postageType, data) => {
    setSelectedPostageType(postageType);
    if (postageType === selectedsWizard) {
      setWizardSelection("");
      props.setActSelectPackingPricing("");
    } else {
      setWizardSelection(postageType);
      props.setActSelectPackingPricing(postageType);
    }
    props.setActSelectPackingPricingData(data);
  };

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    palette: { transparent, info },
  }) => ({
    width: "100%",
    height: "300px",
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      border: `${borderWidth[2]} solid ${info.main} !important`,
    },
  });

  return (
    <Spin tip="Loading..." spinning={loadPostage}>
      <VuiBox>
        {/* <Breadcrumbs
          icon="home"
          title="Postage Type"
          route={[
            "dashboard",
            "General",
            "Sender Info",
            "Recipient Info",
            "Package Type",
            "Postage Type",
          ]}
        /> */}
        <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
          <VuiBox mb={1}>
            <VuiTypography variant="lg" fontWeight="bold" color="inherit">
              SELECT POSTAGE TYPE
            </VuiTypography>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              Note: Your document has {props.PageCount} pages. Pricing shown includes postage, production and printing cost.
            </p>
          </VuiBox>
        </VuiBox>
        <VuiBox mt={2}>
          <Grid container spacing={2} justifyContent="left">
            {packageTypes.map((_package) => {
              return (
                <Grid item xs={12} md={4}>
                  <VuiBox textAlign="left">
                    <VuiButton
                      sx={customButtonStyles}
                      color="info"
                      variant={
                        selectedPostageType == _package.ServiceType ? "contained" : "outlined"
                      }
                      onClick={() => {
                        handleWizardSelect(_package.ServiceType, _package);
                      }}
                    >
                      <Grid container>
                        <Grid item md={12}>
                          <div style={{ fontWeight: 700, fontSize: 16 }}>
                            <TokenIcon style={{ width: "4rem", height: "4rem" }} />

                            <div style={{ color: "" }}>{_package.DisplayName}</div>
                          </div>
                        </Grid>
                        <Grid item md={12}>
                          <span style={{ fontSize: 30, color: "", marginRight: 10 }}>
                            {`$${parseFloat(_package.Total).toFixed(2)}`}
                          </span>
                        </Grid>
                        <Grid item md={12}>
                          <span style={{ fontSize: '0.875rem', color: "", textTransform: 'initial' }}>
                            {_package?.Description}
                          </span>
                          {/* <VuiTypography variant="body2">{_package?.Description}</VuiTypography> */}
                        </Grid>
                      </Grid>
                    </VuiButton>
                  </VuiBox>
                </Grid>
              );
            })}
          </Grid>
        </VuiBox>
      </VuiBox>
    </Spin>
  );
}

export default connect(
  (state) => ({
    loader: state.CommonStore.loader,
    packageType: state.WizardStore.packageType,
    packageData: state.WizardStore.packageData,
    packagePricings: state.WizardStore.packagePricings,
    SelectedPackagePricing: state.WizardStore.SelectedPackagePricing,
  }),
  { PostPackagePricings, setActSelectPackingPricing, setActSelectPackingPricingData }
)(PostageType);
