

import { useState, useEffect, useMemo } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "routes";
// Vision UI Dashboard PRO React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoute from "components/Routes/Routes";
import { connect } from "react-redux";
import { setShiper } from "redux/Actions/profile";
import { setLogin, setLoaderStatus, GetProfile } from "redux/Actions/common";
import { useHistory } from "react-router-dom";
import axios from "axios";

require("dotenv").config();

function App(props) {
  const history = useHistory();

  axios.interceptors.response.use(undefined, function error(err) {
    if (err?.response?.status == 401 || err?.response?.status == 403) {
      const logout_uri = "/sign-in";
      localStorage.clear();
      history.push(logout_uri);
    }
    return Promise.reject(err);
  });

  const { setLoaderStatus } = props;
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const [onboardedStatus, setOnboardedStatus] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  useEffect(() => {
    setLoaderStatus(false);
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  const onBoardingCompleted = () => {
    let data = {
      profile_completed: false,
      subscription_completed: false,
      identity_verified: false,
      status: false
    };

    if (props?.Profile != null && props?.Profile != undefined) {
      data.profile_completed = true;
      data.identity_verified = props?.Profile?.IdentityVerified;
    }
    if (props?.Profile?.SubscriptionInfo?.IsActive == true) {
      data.subscription_completed = true;
    }
    if (data.profile_completed == true && data.subscription_completed == true && data.identity_verified == true) {
      data.status = true;
    }

    setOnboardedStatus(data.status);

    return data;
  }

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    let onboarding = onBoardingCompleted();
    var path = pathname;

    if (localStorage.id_token && onboarding.status == false && path !== "/onboarding") {
      history.push("/onboarding");
    }
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && window.location.pathname != '/rate-calculator' && (
        <>
          {onboardedStatus ? <Sidenav
            color={sidenavColor}
            brandName="DPS"
            routes={routes}
          // onMouseEnter={handleOnMouseEnter}
          // onMouseLeave={handleOnMouseLeave}
          /> : null
          }
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}

      {localStorage.id_token !== null ? <AppRoute /> : ""}
    </ThemeProvider>
  );
}

export default connect(
  (state) => ({
    ShipperHash: state.ProfileStore.ShipperHash,
    Profile: state.CommonStore.Profile,
    profile_status: state.CommonStore.profile_status,
    loader: state.CommonStore.loader,
  }),
  { setShiper, setLogin, setLoaderStatus, GetProfile }
)(App);
